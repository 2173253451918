'use client';

import { Section } from '@/components/section/section';
import { ArrowLeftToLine } from 'lucide-react';
import Link from 'next/link';
import { useEffect } from 'react';

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    console.warn(error);
  }, [error]);

  return (
    <Section>
      <div className="m-auto flex flex-col items-center justify-center py-20">
        <h2 className="mb-4 text-center text-2xl font-medium">
          We hebben deze pagina niet gevonden
        </h2>
        <p className="mb-4 max-w-72 text-center">
          Als u hier wel een pagina had verwacht, horen wij het graag!
        </p>
        <Link href="/" className="flex items-center text-center text-sm hover:underline">
          <ArrowLeftToLine className="mr-2" /> Terug naar home
        </Link>
      </div>
    </Section>
  );
}
